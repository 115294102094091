.status-changer {
    padding: 25px 0px;
    line-height: 18px;
}

.status-menu::after {
    position: absolute;
    left: 40px;
    margin-left: -20px;
    top: -10px;
    width: 0;
    height: 0;
    content: '';
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
}

.status-menu::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid rgba(60, 73, 81, 0.16);
    position: absolute;
    top: -11px;
    left: 19px;
}

.ant-dropdown-menu.status-menu {
    -webkit-box-shadow: 0 20px 35px 0 #C8CFDF, 0 4px 0 0 #C8CFDF;
    box-shadow: 0 20px 35px 0 #C8CFDF, 0 4px 0 0 #C8CFDF;
}

.status-menu {
    margin-top: -10px !important;
    box-sizing: border-box;
    width: 174px;
    border: 1px solid rgba(60,73,81,0.06);
    background-color: #FFFFFF;
    box-shadow: 0 20px 35px 0 #C8CFDF, 0 4px 0 0 #C8CFDF;
    padding: 0px !important;
    border-radius: 8px !important;
}

.status-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
    box-sizing: border-box;
    padding: 14px 20.5px;
    border-top: 1px solid rgba(60,73,81,0.16);
    background-color: #FFFFFF;
    color: #2C3F5E;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
}
.status-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child:hover {
    background-color: #f5f5f5;
}

.status-menu .ant-dropdown-menu-submenu-popup > .ant-dropdown-menu {
    width: 224px !important;
    padding: 0px;
    margin-top: -4px;
    margin-left: -3px;
    border-radius: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: 1px solid rgba(60,73,81,0.16);
    box-shadow: 0 20px 35px 0 rgba(103,124,177,0.27), 0 4px 0 0 rgba(35,57,91,0.08);
}

.status-menu .ant-dropdown-menu-submenu-popup > .ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
    box-sizing: border-box;
    padding: 17px 28.5px !important;
    border-top: 1px solid rgba(60,73,81,0.16);
    background-color: #FFFFFF;
    color: #2C3F5E;
    font-family: "Product Sans";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
}
.status-menu .ant-dropdown-menu-submenu-popup > .ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child:hover {
    background-color: #f5f5f5;
}
.status-menu .ant-dropdown-menu-submenu-popup > .ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-top: 0;
}
.status-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.status-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child:nth-child(1) {
    border: none;
}
.status-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.status-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.status-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child.active {
    background: #E8EDFE;
}